@import '~@opengov/capital-style/scss/tokens';

.headerContainer {
    padding: $unit-half 9px $unit-half 9px;

    .headerContent {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: $unit-4;

        .headerDetails {
            align-items: center;
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            flex-wrap: wrap;
            gap: $unit-1;
            justify-content: space-between;
            max-width: 100%;

            .headerTitle {
                display: flex;
                flex-direction: column;
                gap: 0;
                max-width: 100%;
            }
        }
    }
}

@media (min-width: 817px) {
    .headerContainer .headerContent .headerDetails {
        max-width: calc(100% - 140px);

        .headerTitle {
            max-width: calc(100% - 330px);
        }
    }
}
